import React from 'react';

import Layout from '../layouts/es.jsx';
import {
  Banner,
  Description,
  Timeline,
  Workshop,
} from '@components/pages/biography';
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../components/seo";

const BiographyPage = () => {
  const {wpPage}  = useStaticQuery(graphql`
      query BioSpanish {
        wpPage(slug: {eq: "biografia"}) {
          id
          date
          title
          slug
          status
          featuredImage {
            node {
              sourceUrl
            }
          }
          language {
            slug
            name
            locale
            id
            homeUrl
            code
          }
          content
          fieldsBio {
            header {
              title
              subtitle
              background {
                altText
                link
                sourceUrl
              }
            }
          }
          seo {
            canonical
            metaDesc
            opengraphDescription
            opengraphImage {
              altText
              link
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphSiteName
            opengraphPublisher
            opengraphTitle
            opengraphType
            opengraphUrl
            schema {
              raw
            }
            title
            twitterDescription
            twitterImage {
              altText
              link
            }
            twitterTitle
          }
        }
      }
      `);
  const { fieldsBio, seo } = wpPage;
  return (
    <>
      <Seo seo={seo}
           author={false}
           lang={wpPage.language.locale}
           thumbnail={wpPage.featuredImage ? wpPage.featuredImage.node.sourceUrl : ''}
           pathname="/biografia"/>
      <Layout pageId="biography" scrollTop={true}>
        <Banner data={fieldsBio.header}/>
        <Description data={wpPage.content} />
      </Layout>
    </>
  );
};

export default BiographyPage;
